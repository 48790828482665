@import 'fonts';
@import 'variables';
@import 'reset';

html {
	font-size: 10px;
	font-family: 'OpenSans', sans-serif;
}

body {
	background: var(--green);
}

#root {
	background: var(--white);
}

.full {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&.top {
		justify-content: flex-start;
	}

	&.left {
		align-items: flex-start;
	}
}

.page {
	width: 100%;
	min-height: 100vh;
	padding: 5rem 0 7.5rem;
}

.inner_page {
	width: 100%;
	min-height: 100vh;
	padding: 5rem 0 0;
}

.spinner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.swal2-title {
	font-size: 2.2rem !important;
}

.swal2-content {
	font-size: 1.4rem !important;
}

.swal2-styled.swal2-confirm {
	min-width: 10rem;
	height: 4.8rem;
	font-size: 1.6rem !important;
	font-weight: bold;
	border-radius: 0.4rem;
	background-color: var(--dark) !important;
	cursor: pointer;
}

.swal2-styled.swal2-cancel {
	min-width: 10rem;
	height: 4.8rem;
	font-size: 1.6rem !important;
	font-weight: bold;
	border-radius: 0.4rem;
}

hr {
	border-left: none;
	border-right: none;
	border-bottom: none;
	border-top: 1px inset var(--gray-ligth);
	margin: 1rem 0;
}