.options {
	$link: &;

	&__content-container {
		width: 100%;
		height: 100%;
		padding: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		#{$link}__profile-picture-wrapper {
			border-radius: 50%;
			border: 0.3rem solid var(--green);
			margin-bottom: 2rem;

			#{$link}__profile-picture {
				width: 12rem;
				height: 12rem;
				border-radius: 50%;
			}
		}

		p {
			width: 100%;
			text-align: center;
			margin-bottom: 1rem;
		}

		#{$link}__profile-name {
			font-size: 2.5rem;
			font-weight: bold;
			color: var(--green);
		}

		#{$link}__profile-category {
			font-size: 1.8rem;
			color: var(--light-green);
		}

		#{$link}__profile-company {
			font-size: 1.8rem;
			color: var(--gray-dark);
		}

		#{$link}__logout {
			width: 100%;
			text-align: center;
			background-color: var(--red);
			padding: 1.5rem;
			color: var(--white);
			font-size: 1.5rem;
			font-weight: bold;
			position: fixed;
			bottom: 7.5rem;
		}
	}
}