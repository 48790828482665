:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --light-green: #43a774;
  --light-green-2: #ecf9ec;
  --barelly-green: #a4dbbe;
  --green: #00593f;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray-ligth: #f6f6f6;
  --gray: #ebebeb;
  --gray-1: #b3b3b3;
  --gray-dark: #797979;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}